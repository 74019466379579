@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --background-blue: #000814;
    --font-family-Roboto: 'Roboto', sans-serif;
    --font-family-Roboto-Slab: 'Roboto Slab', serif;
    --font-brown-1: #F3D5B5;
    --bg-brown-2: #442D22;
    --simple-white: #FFFFFF;
}

body {
    background-color: var(--background-blue);
}

.grid-wrapper {
    display: grid;
    margin: 0 auto;
    padding-top: 74px;
    min-height: 100vh;
    width: 98vw;
}